<template>
  <div v-if="isVisible" class="cookie-banner">
    <p>This website uses cookies to enhance the user experience. Do you accept the use of cookies?</p>
    <div>
      <v-btn @click="acceptCookies" color="info">Sure</v-btn>
      <v-btn @click="denyCookies" color="error">No thanks</v-btn>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const isVisible = ref(true);
const EXPIRATION_DAYS = 30;

onMounted(() => {
  const cookieConsent = JSON.parse(localStorage.getItem('cookieConsent'));

  if (cookieConsent) {
    const now = new Date();
    const expires = new Date(cookieConsent.expires);

    if (now > expires) {
      localStorage.removeItem('cookieConsent');

      window.gtag('consent', 'update', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied',
      });
    } else {
      isVisible.value = false;
    }
  }
});

function acceptCookies() {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + EXPIRATION_DAYS);

  localStorage.setItem('cookieConsent', JSON.stringify({
    consent: 'granted',
    expires: expirationDate.toISOString()
  }));

  window.gtag('consent', 'update', {
    'ad_storage': 'granted',
    'ad_user_data': 'granted',
    'ad_personalization': 'granted',
    'analytics_storage': 'granted',
  });

  isVisible.value = false;
}

function denyCookies() {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + EXPIRATION_DAYS);

  localStorage.setItem('cookieConsent', JSON.stringify({
    consent: 'denied',
    expires: expirationDate.toISOString()
  }));

  window.gtag('consent', 'update', {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'denied',
  });

  isVisible.value = false;
}
</script>

<style scoped>
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: rgb(var(--v-theme-background-lighten-2));
  z-index: 1000;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
</style>
