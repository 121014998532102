<template>
  <nav>
    <div class="left">
      <router-link to="/" class="logo">
        <img src="@/assets/logo.png" alt="Logo" loading="lazy">
      </router-link>
      <div class="pre-burger">
        <a href="https://docs.vueboilerplate.com/" target="_blank">Documentation</a>
        <router-link v-for="(item, i) in links" :key="i" :to="item.link">{{ item.title }}</router-link>
      </div>
    </div>
    <div>
      <router-link to="/user-page" class="pre-burger">
        <v-btn icon="mdi-account" variant="text"></v-btn>
      </router-link>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-dots-vertical" variant="text" v-bind="props" class="burger"></v-btn>
        </template>

        <v-list bg-color="background-lighten-3">
          <v-list-item to="/user-page" prepend-icon="mdi-account">
            <v-list-item-title>Userpage</v-list-item-title>
          </v-list-item>

          <v-list-item v-for="(item, i) in links" :key="i" :to="item.link" :prepend-icon="item.icon">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>
          
          <v-btn color="background-lighten-3" variant="flat" class="v-list-item external-link text-none" href="https://docs.vueboilerplate.com/" target="_blank">
            <v-list-item-title>Documentation</v-list-item-title>
          </v-btn>
        </v-list>
      </v-menu>
    </div>
  </nav>
</template>

<script setup>
import { ref } from 'vue';

const links = ref([
  {
    title: 'Changelog',
    link: '/changelog',
    icon: 'mdi-list-box',
  },
  {
    title: 'Blog',
    link: '/blog',
    icon: 'mdi-post',
  },
]);

</script>

<style scoped>
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(var(--v-theme-background-lighten-2));
  padding: 1rem;
  div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .left {
    gap: 3rem;
    .pre-burger {
      gap: 3rem;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

.external-link {
  height: 48px !important;
  .v-list-item-title {
    margin-left: -15px;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  height: 50px;
}

@media screen and (min-width: 951px) {
  .burger {
    display: none;
  }
}

@media screen and (max-width: 950px) {
  .pre-burger {
    display: none;
  }
}

.active-link {
  background-color: rgb(var(--v-theme-primary-lighten-4));
}
</style>
