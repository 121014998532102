<template>
  <div class="outer-container">
    <div>
      <CookieBannerComponent/>
      <PromotionBarComponent/>
      <NavComponent/>
      <div class="router-container">
        <router-view/>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<script setup>
import CookieBannerComponent from '@/components/shared/CookieBannerComponent.vue'
import PromotionBarComponent from '@/components/frontpage/PromotionBarComponent.vue'
import NavComponent from '@/components/shared/NavComponent.vue'
import FooterComponent from '@/components/shared/FooterComponent.vue'

import { ref, provide, onMounted } from 'vue';
import { onAuthChange } from '@/services/firebase.service.js';

const user = ref(null);
const isAuthenticated = ref(false);

onMounted(() => {
  onAuthChange((authUser) => {
    if (authUser) {
      user.value = authUser;
      isAuthenticated.value = true;
    } else {
      user.value = null;
      isAuthenticated.value = false;
    }
  });
});

provide('user', user);
provide('isAuthenticated', isAuthenticated);
</script>

<style scoped>
.outer-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.router-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

<style>
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(var(--v-theme-text));
  line-height: 1.5;
  background-color: rgb(var(--v-theme-background));
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

button, input, textarea, select {
  font-family: inherit;
  color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

.pre-title {
	text-align: center;
	color: rgb(var(--v-theme-primary));
	font-size: medium;
	margin-bottom: 1rem;
	font-weight: 600;
}

.title {
	text-align: center;
	margin-bottom: 3rem;
	font-size: xx-large;
}

.wrapper {
  padding: 1rem;
  margin-block: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 1024px;
}

.outer-wrapper {
  margin-block: 5rem;
}
</style>
