<template>
  
  <footer>
    <div class="wrapper">
      <div class="footer-item-container">
        <div class="copyright">
          <p>Copyright &#169; {{ new Date().getFullYear() }} - All rights reserved</p>
          <div class="built-with">
            <img src="../../assets/logo.png" alt="">
            <p>Built with VueBoilerplate</p>
          </div>
        </div>
        <div class="link-container">
          <div class="links">
            <h2>Links</h2>
            <router-link v-for="(link, i) in links" :key="i" :to="link.link">{{ link.title }}</router-link>
          </div>
          <div class="links">
            <h2>Legal</h2>
            <router-link v-for="(legal, i) in legalLinks" :key="i" :to="legal.link">{{ legal.title }}</router-link>
          </div>
          <div class="links">
            <h2>Socials</h2>
            <a v-for="(social, i) in socialLinks" :key="i" :href="social.link" target="_blank">{{ social.title }}</a>
          </div>
          <div class="links">
            <h2>More by me</h2>
            <a v-for="(project, i) in byMeLinks" :key="i" :href="project.link" target="_blank">{{ project.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue';

const links = ref([
  {
    title: 'Userpage',
    link: '/user-page',
  },
  {
    title: 'Changelog',
    link: '/changelog',
  },
  {
    title: 'Blog',
    link: '/blog',
  },
]);

const legalLinks = ref([
  {
    title: 'Terms of Service',
    link: '/terms-of-service',
  },
  {
    title: 'Privacy Policy',
    link: '/privacy-policy',
  },
]);

const socialLinks = ref([
  {
    title: 'Discord',
    link: 'https://discord.com/',
  },
]);

const byMeLinks = ref([
  {
    title: 'AFK Console Client',
    link: 'https://minecraftafk.com/',
  },
  {
    title: 'Touch Grass Store',
    link: 'https://touchgrass.store/',
  },
  {
    title: 'Minecraft Server List',
    link: 'https://minecraftfyp.com/',
  },
]);
</script>

<style scoped>
footer {
  background-color: rgb(var(--v-theme-background-lighten-2));
  display: flex;
  justify-content: center;
}

.footer-item-container {
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

.link-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.links {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

a:hover {
  text-decoration: underline;;
}

.copyright {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.built-with {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 1rem;
  background-color: rgb(var(--v-theme-info));
  border-radius: 4px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  img {
    height: 1rem;
  }
}
</style>
