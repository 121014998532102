<template>
  <div>
    <router-link :to="{ path: '/', hash: '#newsletter' }"><p><v-icon>mdi-tools</v-icon>VueBoilerplate is under development. Sign up to the newsletter!</p></router-link>
  </div>
</template>

<style scoped>
div {
  background-color: rgb(var(--v-theme-primary));
  width: 100%;
  top: 0;
  /* position: sticky; */
  z-index: 1000;
  display: flex;
  justify-content: center;
  font-size: small;
  padding: 0.5rem;
}
.v-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
p {
  text-align: center;
}
</style>
