import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { useHead } from '@unhead/vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/FrontpageView.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/RegisterView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot_password',
    component: () => import('@/views/ForgotPasswordView.vue'),
  },
  {
    path: '/user-page',
    name: 'user_page',
    component: () => import('@/views/UserPageView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Userpage | VueBoilerplate',
      description: 'Manage your account and subscription.',
    },
  },
  {
    path: '/changelog',
    name: 'changelog',
    component: () => import('@/views/ChangelogView.vue'),
    meta: {
      title: 'Changelog | VueBoilerplate',
      description: 'Read our changelog.',
    },
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/BlogListView.vue'),
    meta: {
      title: 'Blog | VueBoilerplate',
      description: 'Read our blog.',
    },
  },
  {
    path: '/blog/:slug',
    name: 'BlogPost',
    component: () => import('@/views/BlogView.vue'),  },
    // Meta title & description is handled dynamically in the component
  {
    path: '/terms-of-service',
    name: 'terms_of_service',
    component: () => import('@/views/TermsOfServiceView.vue'),
    meta: {
      title: 'Terms of Service | VueBoilerplate',
      description: 'Read our terms of service.',
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy_policy',
    component: () => import('@/views/PrivacyPolicyView.vue'),
    meta: {
      title: 'Privacy Policy | VueBoilerplate',
      description: 'Read our privacy policy.',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404_not_found',
    component: () => import('@/views/NotFound404View.vue'),
    meta: {
      title: '404 Not Found | VueBoilerplate',
      description: 'This page doesn\'t exist.',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }
    return savedPosition || { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const auth = getAuth();

  if (requiresAuth) {
    onAuthStateChanged(auth, user => {
      if (user) {
        applyHeadMeta(to);
        next();
      } else {
        next('/login');
      }
    });
  } else {
    applyHeadMeta(to);
    next();
  }
});

function applyHeadMeta(to) {
  const title = to.meta.title || 'VueBoilerplate | Build, launch and succeed';
  const description = to.meta.description || 'Launch your project in minutes and focus on growing your business. This boilerplate includes payment integration, an admin panel, authentication, SEO, tracking, a CMS and much more!';
  useHead({
    title,
    meta: [
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ],
  });

  const schemaScripts = document.querySelectorAll('script[type="application/ld+json"]');
  schemaScripts.forEach(script => script.remove());
}

export default router;
